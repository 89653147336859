import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue } from '@digital-magic/ts-common-utils'
import { AuthenticationRequest } from '@api/endpoints/auth/types'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { useAuthContext } from '@hooks/AuthContext'
import { Alert, Box, Button } from '@mui/material'
import { Page } from '@layout/Page'
import { FormTextField, useZodForm } from '@controls/Form'

export const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const { login, logoutReason } = useAuthContext()
  const { logoutReasonTranslation } = useEnumTranslation()
  const pageId = React.useId()

  const form = useZodForm({ schema: AuthenticationRequest })

  return (
    <Page id={pageId} showFooter={false} showMenu={false}>
      {hasValue(logoutReason) && <Alert severity="error">{logoutReasonTranslation(logoutReason)}</Alert>}
      <form onSubmit={form.handleSubmit(login)}>
        <Box display="flex" flexDirection="column" rowGap={2} width="70%" maxWidth="400px" mx="auto">
          <h1>{t('pages.login.title')}</h1>
          <FormTextField
            control={form.control}
            name="userName"
            type="text"
            label={t('pages.login.form.fields.username')}
          />
          <FormTextField
            control={form.control}
            name="password"
            type="password"
            label={t('pages.login.form.fields.password')}
          />
          <Button variant="contained" type="submit">
            {t('pages.login.form.buttons.submit')}
          </Button>
        </Box>
      </form>
    </Page>
  )
}
